<template>
  <modal
    id="createRangeModal"
    :title="'Create Score Range'"
    ref="createRangeModal"
    :size="'md'"
  >
    <form @submit.prevent="submitRange">
      <div class="mx-2">
        <div class="row">
          <div class="col-12">
            <div class="w-100">
              <label for="name" class="d-flex gap align-items-center">
                <span class="text-uppercase">Name</span>

                <span class="text-danger" style="text-transform: lowercase">
                  * <span v-if="isSubmit && !createRange.name">required</span>
                </span>
              </label>
              <input
              required
                type="text"
                name=""
                v-model.trim="createRange.name"
                id="name"
                class="quizell-form-control w-100 px-2"
              />
            </div>
          </div>

          <div class="col-12">
            <div class="w-100 mt-3">
              <label for="name" class="d-flex gap align-items-center">
                <span class="text-uppercase">Quiz</span>

                <span class="text-danger" style="text-transform: lowercase">
                  *
                  <span v-if="isSubmit && !createRange.quiz_id">required</span>
                </span>
              </label>
              <div
                v-if="isLoadingQuiz"
                class="bg-preload mt-2"
                style="height: 40px; width: 100%; border-radius: 4px"
              >
                <div class="into-preload"></div>
              </div>
              <select
                v-else
                name=""
                required
                id="sender-email-address"
                v-model="createRange.quiz_id"
                class="quizell-form-control w-100 px-2"
              >
                <option value="">Select a Quiz</option>
                <option :value="quiz.id" v-for="quiz in quizzes" :key="quiz.id">
                  {{ quiz.title }}
                </option>
              </select>
            </div>
          </div>

          <div class="col-md-6 col-12">
            <div class="w-100 mt-3">
              <label for="min" class="d-flex gap align-items-center">
                <span class="text-uppercase">Minimum</span>

                <span class="text-danger" style="text-transform: lowercase">
                </span>
              </label>
              <input
                type="number"
                name=""
                id="min"
                required
                v-model="createRange.min_score"
                class="quizell-form-control w-100 px-2"
              />
            </div>
          </div>
          <div class="col-md-6 col-12">
            <div class="w-100 mt-3">
              <label for="max" class="d-flex gap align-items-center">
                <span class="text-uppercase">Maximum</span>

                
              </label>
              <input
                type="number"
                name=""
                required
                :min="createRange.min_score"
                id="max"
                 v-model.number="createRange.max_score"
                class="quizell-form-control w-100 px-2"
              />

              <label class="text-danger mt-2" v-if="(isSubmit&&(!checkMaximumValidation))" style="text-transform: lowercase">
                    maximum should be greater than {{ createRange.min_score }}
                </label>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-center mt-3">
          <Button :isLoading="isLoading" type="submit">Create</Button>
        </div>
      </div>
    </form>
  </modal>
</template>

<script>
import Button from "../../../Layout/Button.vue";
import axios from "axios";
import Modal from "../../../Layout/Modal.vue";
export default {
  components: {
    Modal,
    Button,
  },
  data() {
    return {
      isSubmit: false,
      isLoading: false,
      isLoadingQuiz: false,
      quizzes: null,
      createRange: {
        quiz_id: "",
        name: "",
        min_score: 0,
        max_score: 0,
      },

    };
  },
  computed: {
    checkMaximumValidation(){
        return (this.createRange.max_score>0&&this.createRange.max_score>this.createRange.min_score)
    },
    checkValidation() {
      if (this.createRange.quiz_id && this.createRange.name) return true;
      return false;
    },
  },
  methods: {
    open() {
      this.$refs.createRangeModal.open();
      if (this.quizzes == null) this.getQuizzes();
    },
    close() {
      this.$refs.createRangeModal.close();
    },
    async getQuizzes() {
      this.isLoadingQuiz = true;
      await axios
        .post("/filter", { page_name: "lead" })
        .then((response) => {
          this.quizzes = response.data.data.quizzes;
        })
        .catch((e) => console.log(e));

      this.isLoadingQuiz = false;
    },
    async submitRange() {
  this.isSubmit = true;

  try {
    if (this.checkValidation&&(this.checkMaximumValidation)) {
      this.isLoading = true;
      const response = await axios.post("/api/v1/flow/score-range/store", this.createRange);
      if(response.status==200){
        this.$refs.createRangeModal.close();
        this.$emit('reload');
        this.$toasted.show("Score added successfully ", {
      theme: "toasted-primary",
      position: "bottom-center",
      duration: 2000,
    });
      }
    }
  } catch (e) {
    this.$toasted.show("Error Occurred", {
      theme: "toasted-primary",
      position: "bottom-center",
      duration: 2000,
    });
    // You might want to log the error or handle it differently
    console.error(e);
  } finally {
    this.isLoading = false;
  }
}

  },
};
</script>

<style scoped>
label {
  color: #73738d;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  text-transform: uppercase;
}
</style>
